import { Breadcrumb, BreadcrumbService } from 'rey-web-toolkit';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Container } from 'typescript-ioc';
import FeatureFlagService from '@/services/FeatureFlagService';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/LandingPage.vue'),
    meta: {
      getBreadcrumbs: () => [],
    },
  },
  {
    path: '/systemInsight',
    name: 'SystemInsight',
    component: () => import('../views/SystemInsight.vue'),
    meta: {
      getBreadcrumbs: () => [new Breadcrumb('SystemInsight')],
    },
  },
  {
    path: '/systemInsight/eventConfiguration',
    name: 'Event Konfiguration',
    component: () => import('../views/EventConfiguration.vue'),
    meta: {
      getBreadcrumbs: () => [new Breadcrumb('SystemInsight'), new Breadcrumb('Event Konfiguration')],
    },
  },
  {
    path: '/workflowInstances',
    name: 'workflowInstances',
    component: () => import('../views/WorkflowInstances.vue'),
    meta: {
      getBreadcrumbs: () => [new Breadcrumb('Workflow Instanzen')],
    },
  },
  {
    path: '/workflowEditor',
    name: 'WorkflowEditorOverview',
    component: () => import('../views/WorkflowEditorOverview.vue'),
    meta: {
      getBreadcrumbs: () => [new Breadcrumb('Workflow Editor Overview')],
    },
  },
  {
    path: '/workflowEditor/:key',
    name: 'WorkflowEditor',
    component: () => import('../views/WorkflowEditor.vue'),
    meta: {
      getBreadcrumbs: () => [new Breadcrumb('Workflow Editor')],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      getBreadcrumbs: () => [new Breadcrumb('Einstellungen')],
    },
  },
  {
    path: '/notFound',
    name: 'Page Not Found',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      getBreadcrumbs: () => [new Breadcrumb('Page not found')],
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      getBreadcrumbs: () => [new Breadcrumb('Page not found')],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to: any, from: any, next: any) => {
  const breadcrumbService = Container.get<BreadcrumbService>(BreadcrumbService);
  const featureFlagService = Container.get<FeatureFlagService>(FeatureFlagService);
  breadcrumbService.automaticRoutePathResolution(to);
  featureFlagService.parse(to.query);
  next();
});

export default router;
