
import pkg from '../package.json';
import { Options, Vue } from 'vue-class-component';
import { ToolkitLayout, PluginStore, IPluginStoreState, ISetting } from 'rey-web-toolkit';

@Options({
  components: {
    ToolkitLayout,
  },
})
export default class App extends Vue {
  public pluginState: IPluginStoreState = PluginStore.getState;
  public GetLogo(): string {
    const logoSetting = this.pluginState.settings.find((s: ISetting) => s.name == 'LogoUrl');
    return logoSetting?.value || logoSetting?.defaultValue || process.env.BASE_URL + 'iDIPLogo.svg';
  }
  public GetApplicationTitle(): string {
    const applicationTitleSetting = this.pluginState.settings.find((s: ISetting) => s.name == 'ApplicationTitle');
    return (
      applicationTitleSetting?.value ?? applicationTitleSetting?.defaultValue ?? 'iDIP - industrial Digitalization and Innovation Platform'
    );
  }
  public GetApplicationName(): string {
    const applicationNameSetting = this.pluginState.settings.find((s: ISetting) => s.name == 'ApplicationName');
    return applicationNameSetting?.value ?? applicationNameSetting?.defaultValue ?? 'iDIP';
  }

  public appVersion: string = pkg.version;
}
