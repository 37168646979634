import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import 'devextreme/dist/css/dx.light.compact.css';
import axios from 'axios';
import { PluginStore } from 'rey-web-toolkit';
import { authenticatedAxiosPlugin, dipAuthClient } from 'dip.toolkit.webui.authentication';

axios.defaults.baseURL = process.env.BASE_URL;
(async () => {
  await PluginStore.load(axios, process.env.BASE_URL);
  const app = createApp(App);

  await dipAuthClient.initialize(router);
  app.use(authenticatedAxiosPlugin);
  app.use(router);
  await dipAuthClient.provider?.ensureAuthenticated();
  await router.isReady();

  app.mount('#app');
})();
