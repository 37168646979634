import { Singleton } from 'typescript-ioc';

@Singleton
export default class FeatureFlagService {
  private enabledFeatures: Set<string> = new Set<string>();
  private readonly featureFlagParam = 'ff';

  public static featureFlags = {
    WF_METHOD: 'wf-method',
    WF_SHARE: 'wf-share',
  };

  public featureEnabled(feature: string): boolean {
    return this.enabledFeatures.has(feature);
  }

  public enableFeature(featureFlag: string) {
    const feature = Object.values(FeatureFlagService.featureFlags).find((v) => v == featureFlag);
    if (feature) {
      this.enabledFeatures.add(feature);
    }
  }

  public parse(query: Record<string, string | string[]>) {
    const flagParam = query[this.featureFlagParam];
    if (flagParam) {
      if (typeof flagParam === 'string') {
        this.enableFeature(flagParam);
      } else if (Array.isArray(flagParam)) {
        flagParam.forEach((flag) => this.enableFeature(flag));
      }
    }
    if (this.enabledFeatures.size > 0) {
      console.info('enabled feature flags:', this.enabledFeatures);
    }
  }

  get isWorkflowShareFeatureEnabled() {
    return this.featureEnabled(FeatureFlagService.featureFlags.WF_SHARE);
  }

  get isWorkflowMethodFeatureEnabled() {
    return this.featureEnabled(FeatureFlagService.featureFlags.WF_METHOD);
  }
}
