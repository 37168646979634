import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ToolkitLayout = _resolveComponent("ToolkitLayout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ToolkitLayout, {
      sidebarEntries: _ctx.pluginState.sidebarEntries,
      logo: _ctx.GetLogo(),
      applicationName: _ctx.GetApplicationName(),
      appVersion: _ctx.appVersion,
      showBreadcrumps: false,
      applicationTitle: _ctx.GetApplicationTitle(),
      disableHyperlinksInSidebarFooter: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["sidebarEntries", "logo", "applicationName", "appVersion", "applicationTitle"])
  ]))
}